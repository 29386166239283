<template>
  <div id="recruitDetail">
    <div class="banner">
      <p>人才招聘</p>
    </div>
    <div class="container">
      <div class="main">
        <div class="top">
          <h2>{{ posDetail.name }}</h2>
          <div>
            <span>岗位类别：{{ posDetail.type }}</span>
            <span>工作地点：{{ posDetail.address }}</span>
            <span>需求人数:{{ posDetail.count }}</span>
            <span>发布时间：{{ posDetail.time }}</span>
            <span>简历投递邮箱：{{ posDetail.email }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="item">
            <h2>岗位职责</h2>
            <span></span>
            <p v-for="(duty, index) in posDetail.duty">{{ duty }}</p>
          </div>
          <div class="item">
            <h2>任职要求</h2>
            <span></span>
            <p v-for="(require, index) in posDetail.require">{{ require }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecruitDetail",
  data() {
    return {};
  },
  computed: {
    posDetail() {
      return this.$route.query.row;
    },
  },
};
</script>
<style lang='scss' scoped>
#recruitDetail {
  width: 100%;
  background: #ffffff;
  font-family: Microsoft YaHei;
  .banner {
    position: relative;
    height: 3.655rem;
    background: url("../assets/image/recruit/b_1.png") no-repeat;
    background-size: cover;
    p:nth-child(1) {
      position: absolute;
      top: 0.92rem;
      left: 5.92rem;
      font-size: 0.36rem;
      font-weight: bold;
      color: #fffefe;
    }
  }
  .container {
    height: 6.09rem;
    .main {
      position: absolute;
      left: 3.5rem;
      top: 3.24rem;
      width: 11.79rem;
      height: 7rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.24rem 0rem rgba(186, 186, 186, 0.44);
      .top {
        width: 100%;
        height: 1.6rem;
        padding-top: 0.001rem;
        background: url("../assets/image/recruit/b_2.png") no-repeat;
        background-size: cover;
        h2 {
          margin: 0.36rem 0 0 0.47rem;
          font-size: 0.24rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        div {
          margin: 0.39rem 0 0 0.47rem;
          span {
            margin-right: 0.43rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
    .bottom {
      width: 100%;
      padding: 0.4rem 0 0 0.46rem;
      .item {
        h2 {
          font-size: 0.16rem;
          font-weight: 400;
          color: #333333;
        }
        span {
          display: inline-block;
          width: 0.35rem;
          height: 0.02rem;
          margin-top: 0.1rem;
          background: #1562ec;
        }
        p {
          width: 10rem;
          line-height: 0.23rem;
          font-size: 0.14rem;
          font-weight: 400;
          color: #666666;
          line-height: 0.28rem;
        }
      }
      .item:nth-child(n + 2) {
        margin-top: 0.45rem;
      }
    }
  }
}
</style>